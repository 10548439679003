import React from 'react'
import PropTypes from 'prop-types'
import Layout from 'components/Layout'
import SEO from 'components/Seo'
import { IntlProvider } from 'contexts/intl/IntlContext'

const Index = ({ location }) => (
  <IntlProvider pathname={location.pathname}>
    {({ locale, formatMessage, getPath }) => (
      <Layout>
        <SEO
          lang={locale}
          title={formatMessage({ id: 'stack.title' })}
          description={formatMessage({ id: 'meta.description' })}
          keywords={formatMessage({ id: 'meta.keywords' })}
          url={formatMessage({ id: 'meta.url.stack' })}
          isBlogPost={true}
          ogTitle={formatMessage({ id: 'stack.title' })}
          ogUrl={formatMessage({ id: 'meta.url.stack' })}
          ogDescription={formatMessage({ id: 'meta.description' })}
          ogKeywords={formatMessage({ id: 'meta.keywords' })}
          ogLocale={locale}
          twitterTitle={formatMessage({ id: 'stack.title' })}
          twitterDescription={formatMessage({ id: 'meta.description' })}
          twitterCreator={'Mihir Pipermitwala'}
          siteName={'Mihir Pipermitwala'}
        />
        <div className='container'>
          <div className='has-text-centered is-mobile'>
            <div className='column'>
              <div className='full-width-image-container margin-top-0'>
                <h2 className='has-text-weight-bold is-size-1'>Contact</h2>
              </div>
              <section className='section'>
                <div className='container'>
                  <div className='tile is-ancestor'>
                    <div className='tile is-vertical is-7'>
                      <div className='tile'>
                        <div className='tile is-parent'>
                          <article className='tile is-child box'>
                            <div className='content'>
                              <h1>Thank you!</h1>
                              <p>I will get in touch soon</p>

                              {/* <h4>For urgent queries find me online:</h4>
                              <ul>
                                <li>
                                  <a href='https://instagram.com/mihirpipermitwala'>@mihirpipermitwala on Instagram</a>
                                </li>
                              </ul>
                              <p>
                                Feel free to send me an email directly. If you have a technical question, you may want
                                to ask it on <a href='http://stackoverflow.com/'>StackOverflow</a> and just email or
                                tweet me the link.
                              </p> */}
                            </div>
                          </article>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </Layout>
    )}
  </IntlProvider>
)

Index.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
}

export default Index
